import React, { useState } from 'react';
import { RouteComponentProps, navigate } from "@reach/router";
import { Routes } from "../../../config/Routes";
import { Login } from '../Login';
import { useBillerAuthService } from "../../../core/service/services";
import { UserConfig } from '../../../config/userConfig';
import { strings } from '../../../Constants';

interface IAgentLogInProps extends IProps {
  email?: string;
}
interface IProps extends RouteComponentProps {
  targetPath?: string
}
export const BillerLogin: React.FC<IProps> = (props: IAgentLogInProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const signInService = useBillerAuthService();


  React.useEffect(() => {
    if (signInService.service.status === "loaded") {
      UserConfig.setToken(signInService.service.payload.accessToken);
      UserConfig.setUserInfo(signInService.service.payload.userInfo);
      UserConfig.setUserType(strings.biller.billerName)

      props.targetPath ? navigate(props.targetPath) : navigate(Routes.homeBiller);

    } else if (signInService.service.status === "error") {
      setErrorMessage("Password is wrong or does not exist");
    } else if (signInService.service.status === "validationError") {
      setErrorMessage('Password is missing or Email is not valid');
    } else if (signInService.service.status === "custormError") {
      setErrorMessage(signInService.service.errorResponse.details);

    }

  });

  const logIn = async (e: { email?: string, password?: string }) => {
    let data = { email: "", password: "" }
    data.email = e.email || "";
    data.password = e.password || "";

    await signInService
      .callService(data)
      .catch((error) => {
        console.log(error);
      });

  }
  return (
    <Login userType={strings.biller.billerName} logIn={logIn} routesTo={Routes.billerSignup} errorMessage={errorMessage} />
  );
}