import React, { useState } from 'react';
import { RouteComponentProps, navigate } from "@reach/router";
import { usePhysicianLoginService } from "../../core/service/services";
import { SmallCardFrame } from "../../components/layout/SmallCardFrame";
import { Routes } from "../../config/Routes";
import { TextField } from "../../components/commons/TextField";
import { UserConfig } from "../../config/userConfig";
import { CardBody, Form, Button } from 'reactstrap';


export const PhysicianAuthCodePage: React.FC<RouteComponentProps> = (props) => {

    const data = {
        email:  (props.location?.state as any)?.email,
        password: (props.location?.state as any)?.password,
        authenticationId: (props.location?.state as any)?.authenticationId,
        targetPath: (props.location?.state as any)?.targetPath,
        authenticationCode: ""
    };

    const physicianLoginService = usePhysicianLoginService();
    const [errorMessage, setErrorMessage] = useState('');

    const inputElementRef = React.useRef<TextField>(null);

    React.useEffect(() => {
        if (!data.authenticationId || data.authenticationId === "") {
          navigate(Routes.physicianSignin)
          return
        }
    
        if (physicianLoginService.service.status === "loaded") {
          UserConfig.setToken(physicianLoginService.service.payload.accessToken);
          UserConfig.setUserInfo(physicianLoginService.service.payload.userInfo);
          UserConfig.setUserType("physician")
          data.targetPath? navigate(data.targetPath) : navigate(Routes.homePhysician);
        } else if (physicianLoginService.service.status === "error") {
          setErrorMessage("Password is wrong or does not exist");
        } else if (physicianLoginService.service.status === "validationError") {
          setErrorMessage('Fields are missing');
        } else if (physicianLoginService.service.status === "custormError" ){
          setErrorMessage(physicianLoginService.service.errorResponse.message);
        }
    
      });
    
      const callLoginService = (e: React.FormEvent) => {
        e.preventDefault()
        let authCode = inputElementRef.current?.getValue();
        if(!authCode) {
            authCode = '';
        }
        data.authenticationCode = authCode;
    
        physicianLoginService
          .callService(data)
          .then((resData) => {
          })
          .catch((error) => {
          });
      };
    return (

        <SmallCardFrame navItem bodyClassName="tc-bg" >
            <CardBody>
                <div className="pre-password-reset">
                  <h5 className="card-title text-muted mb-1">AuthenticationCode</h5>
                  <p>Please type the authentication code sent to your mailbox:</p>
                  <hr />
                  <Form onSubmit={callLoginService}>
                    <TextField ref={inputElementRef} type="text" labelText="Authentication Code" placeholder="code" />
                    <div className="text-center mb-4 mt-4" style={{color: "red"}}>
                        {errorMessage}
                    </div>
                    <Button type="submit" color="primary" size="lg" className="w-50 m-auto text-uppercase btn-forgot-pw d-block mt-4">
                        {physicianLoginService.service.status === "loading" ? <><span className="spinner-border spinner-border-sm" 
                          role="status" aria-hidden="true"></span> Loading... </> : <> Submit Code </>}
                    </Button>
                  </Form>
                </div>
            </CardBody>
        </SmallCardFrame>
    )
}