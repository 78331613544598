// import { render } from "@testing-library/react";
import * as React from "react";
import { RouteComponentProps, Link, navigate } from "@reach/router";
import { Routes } from "../../config/Routes";
import { PreviousConditionsModal, IItem } from "./PreviousConditionsModal";
import { MedicationsModal } from "./MedicationsModal";
import {
  getSelectedConditions,
  getAllConditionsLookup,
  setPatientMeasurments,
  getPatientMeasurments,
  setPatientMedications,
  IModifyMedicationsRequest,
  IPatientMeasurment,
  getMedicationAllergies,
  addMedicationAllergies,
  deleteMedicationAllergies,
  updateMedicationAllergies,
  getMedicationsAllergyStatus,
  editMedicationsAllergyStatus,
  getPreviousConditionsStatus,
  ISelfReportedItem,
  addSelfReportedMedicationService,
  removeSelfReportedMedicationService,
  requestPatientInfo,
  logAgentUpdatingPatientMedicalHistory,
  IPatientPreviousConditions,
  updatePatientPreviousConditions,
  IFullSelfReportedItem,
} from "../../core/service/services";
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  FormGroup,
  Label,
  Button,
  Form,
} from "reactstrap";
import { BigCardFrame } from "../../components/layout/BigCardFrame";
import { TextField } from "../../components/commons/TextField";
import Asterisk from "../../components/commons/Asterisk";
import {
  IFullAllergy,
  IAddAllergy,
} from "../../components/medicalHistory/MedicationAllergiesDoseSpot";
import MedicationsAllergiesList from "../../components/medicalHistory/MedicationsAllergiesList";
import { toastError, navItem } from "../../App";
import { SelfReportedMedications } from "./SelfReportedMedications";
import MembersInHousehold from "../../components/patientSettings/MembersInHousehold";
import { IPatientInfoSettings } from "../../components/patientSettings/PatientSettings";
import { UserConfig } from "../../config/userConfig";
import { LoadingButton } from "../../components/commons/LoadingButton";

interface IProps extends RouteComponentProps {} //, WithTranslation {}
interface IState {}
// interface IPreviousConditionsStatus{
//   exist:boolean,
//   has
// }

interface IMeasurement {
  ft?: string;
  inch?: string;
  lbs?: string;
  gender?: "Male" | "Female";
  isEditing: boolean;
  pregnant?: boolean;
  breastfeeding?: boolean;
}

export interface ISelfReportedMedicationsAction {
  medication: IFullSelfReportedItem;
  action: "add" | "remove";
}

export const MedicalHistory: React.SFC<IProps> = (props) => {
  const [measurmentsState, setMeasurmentsState] = React.useState<IMeasurement>({
    isEditing: false,
    inch: "",
    ft: "",
    lbs: "",
    pregnant: false,
    breastfeeding: false,
  });

  const [modal, setModal] = React.useState<React.ReactNode>(null);
  const [conditionsState, setConditionsState] = React.useState<IItem[]>([]);
  const [medicationsState, setMedicationsState] = React.useState<IItem[]>([]);
  const [medicationsAllergy, setMedicationsAllergy] = React.useState<any>();
  const [newMedicationsAllergy, setNewMedicationsAllergy] =
    React.useState<IAddAllergy[]>();
  const [deletedMedicationsAllergy, setDeletedMedicationsAllergy] =
    React.useState<IFullAllergy[]>();
  const [editedMedicationsAllergy, setEditedMedicationsAllergy] =
    React.useState<IFullAllergy[]>();
  const [allergic, setAllergic] = React.useState<boolean>();
  const [newAllergic, setNewAllergic] = React.useState<boolean>(true);
  const [previousConditionsStatus, setPreviousConditionsStatus] =
    React.useState<boolean>();
  const [newPreviousConditionsStatus, setNewPreviousConditionsStatus] =
    React.useState<boolean>();
  const [selfReportedMedicationsActions, setSelfReportedMedicationsActions] =
    React.useState<ISelfReportedMedicationsAction[]>([]);
  const [userInfo, setUserInfo] = React.useState<IPatientInfoSettings>({});
  const [genderEditMode, setGenderEditMode] = React.useState<boolean>(false);
  const toggleGenderEditMode = () => {
    setGenderEditMode(!genderEditMode);
  };
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  let ftRef = React.useRef<TextField>(null);
  let lbsRef = React.useRef<TextField>(null);
  let inchRef = React.useRef<TextField>(null);
  let pregnantRef = React.useRef<HTMLInputElement>(null);
  let breastfeedingRef = React.useRef<HTMLInputElement>(null);

  const updateMeasurmentStateFromFields = () => {
    let state = { ...measurmentsState };
    if (state.isEditing) {
      state.inch = inchRef.current?.getValue();
      state.ft = ftRef.current?.getValue();
      state.lbs = lbsRef.current?.getValue();
      state.pregnant = pregnantRef.current?.checked;
      state.breastfeeding = breastfeedingRef.current?.checked;
    }
    // if (togglreEriting) {
    //   state.isEditing = !state.isEditing;
    // }
    setMeasurmentsState(state);
  };

  let conditions: { name: string; id: number }[] = [];
  let selectedConditions: number[] = [];
  const getData = () => {
    getSelectedConditions()
      .then((res) => {
        selectedConditions = res.symptoms;
        updateSelectedConditions();
      })
      .catch((error) => console.error(error));

    getAllConditionsLookup()
      .then((res) => {
        conditions = res;
        updateSelectedConditions();
      })
      .catch((error) => console.error(error));

    getPatientMeasurments()
      .then((res) => {
        let gender: "Male" | "Female" | undefined;
        if (res.gender == "f") gender = "Female";
        if (res.gender == "m") gender = "Male";
        setMeasurmentsState({
          ft: "" + (res.heightFt || ""),
          isEditing: false,
          gender: gender,
          inch: "" + (res.heightIn || ""),
          lbs: res.weight ? "" + res.weight : "",
          pregnant: res.pregnant || false,
          breastfeeding: res.breastfeeding || false,
        });
      })
      .catch((error) => console.error(error));

    getMedicationAllergies()
      .then((result) => {
        setMedicationsAllergy(result);
      })
      .catch((error) => {
        console.log(error);
      });

    getMedicationsAllergyStatus()
      .then((result: any) => {
        if (result.exist === true) setAllergic(result.hasMedicationsAllergy);
      })
      .catch((error) => {
        console.log(error);
      });
    getPreviousConditionsStatus()
      .then((result: any) => {
        if (result.exist === true)
          setPreviousConditionsStatus(result.hasPreviousConditions);
      })
      .catch((error) => {
        console.log(error);
      });

    requestPatientInfo("GET")
      .then((res) => {
        setUserInfo(res);
      })
      .catch((err) => console.log(err));
  };
  const updateSelectedConditions = () => {
    setConditionsState(
      conditions.map((c) => {
        return {
          id: c.id,
          display: c.name,
          checked: selectedConditions.includes(c.id),
          item: c,
        };
      })
    );
  };
  const savePreviousConditions = (items: IItem[]) => {
    setConditionsState(items);
    setModal(null);
  };

  React.useEffect(() => {
    getData();
    setIsLoading(false);
  }, []);
  const returnErrorMessage = (error: any) => {
    let errorObj = JSON.parse(JSON.stringify(error));
    if (errorObj && errorObj.json) {
      let errorMessageStr = errorObj.json.message;
      let errorMessage = "";
      if (errorMessageStr.split(",").length > 1)
        errorMessage = errorMessageStr.split(",")[1];
      else errorMessage = errorMessageStr;
      return errorMessage;
    } else {
      return error;
    }
  };
  const confirmChanges = async () => {
    setIsLoading(true);
    let status = {
      conditions: false,
      measurments: false,
      medications: false,
    };
    let errorArr: any[] = [];
    let logError = (e: any, message?: string) => {
      errorArr.push(e);
      if (errorArr.length > 0) {
        toastError(message ? message : "Errors encountered");
        console.log(e);
      }
    };
    updateMeasurmentStateFromFields();
    let selfReposrtedPromises = selfReportedMedicationsActions.forEach(
      async (item) => {
        if (item.action === "add") {
          return await addSelfReportedMedicationService(
            item.medication
          ).catch((err) => console.log(err));
        } else if (item.medication.id) {
          return await removeSelfReportedMedicationService(
            item.medication.id,
          ).catch((err) => console.log(err));
        }
      }
    );

    let gender: "m" | "f" | undefined;
    if (measurmentsState.gender == "Male") gender = "m";
    if (measurmentsState.gender == "Female") gender = "f";
    let measurments: IPatientMeasurment = {
      gender: gender,
      heightFt: Number.parseFloat(measurmentsState.ft || ""),
      heightIn: Number.parseFloat(measurmentsState.inch || ""),
      weight: Number.parseFloat(measurmentsState.lbs || ""),
      pregnant: measurmentsState.pregnant || false,
      breastfeeding: measurmentsState.breastfeeding || false,
    };

    // if (measurmentsState.isEditing) {
    measurments.heightIn = Number(inchRef.current?.getValue());
    measurments.heightFt = Number(ftRef.current?.getValue());
    measurments.weight = Number(lbsRef.current?.getValue());
    measurments.pregnant = pregnantRef.current?.checked;
    measurments.breastfeeding = breastfeedingRef.current?.checked;
    // }

    let measurmentsPromises = await setPatientMeasurments(measurments).catch(
      (error) => {
        let err = returnErrorMessage(error);
        toastError(err);
      }
    );
    if (measurmentsPromises) status.measurments = true;

    const navigateIfDone = () => {
      navigate(Routes.homePatient);
    };

    let patientMedicationsPromises = await setPatientMedications(
      medicationsState.reduce<IModifyMedicationsRequest>(
        (req: IModifyMedicationsRequest, m: IItem) => {
          if (m.checked && m.item == null) {
            req.add.push(m.display);
          } else if (m.checked && m.item != null) {
            m.item.name = m.display;
            req.modify.push(m.item);
          } else if (!m.checked && m.item != null) {
            req.remove.push(m.id as number);
          }
          return req;
        },
        { add: [], modify: [], remove: [] }
      )
    ).catch((error) => {
      console.error(error);
    });
    if (patientMedicationsPromises) status.medications = true;
    let medicationAllergiesPromises;
    if (newMedicationsAllergy) {
      medicationAllergiesPromises = addMedicationAllergies(
        newMedicationsAllergy
      ).catch((error) => {
        let obj: any = Object.values(error)[2];
        logError(error, obj.message);
        // toastError(obj.message);
      });
    }

    let deleteMedicationAllergiesPromises;
    if (deletedMedicationsAllergy) {
      deleteMedicationAllergiesPromises = await deleteMedicationAllergies(
        deletedMedicationsAllergy
      ).catch((error) => {
        console.log(error);
      });
    }
    let updatedMedicationsAllergyPromises;
    if (editedMedicationsAllergy) {
      updatedMedicationsAllergyPromises = await updateMedicationAllergies(
        editedMedicationsAllergy
      ).catch((error) => {
        console.log(error);
      });
    }

    let body = {
      hasMedicationsAllergy: newAllergic,
    };
    let editMedicationsAllergyStatusPromises =
      await editMedicationsAllergyStatus(body.hasMedicationsAllergy).catch((error) => {
        logError(error, "Medications Allergies status cannot be changed!");
      });

    let selectedConditionsPromises;
    if (newPreviousConditionsStatus !== undefined) {
      let body: IPatientPreviousConditions = {
        hasPreviousCondition: newPreviousConditionsStatus,
        conditions: conditionsState
          .filter((v) => v.checked)
          .map((v) => v.id as number),
      };
      selectedConditionsPromises = await updatePatientPreviousConditions(
        body
      ).catch((error) => {
        logError(error, "Previous Conditions cannot be updated!");
      });
    }
    if (selectedConditionsPromises) status.conditions = true;
    let responses = await Promise.all([
      editMedicationsAllergyStatusPromises,
      measurmentsPromises,
      patientMedicationsPromises,
      selectedConditionsPromises,
      selfReposrtedPromises,
      updatedMedicationsAllergyPromises,
      deleteMedicationAllergiesPromises,
      medicationAllergiesPromises,
    ]).catch(() => {
      toastError("Couldn't save your progress");
    });
    if (UserConfig.getUserType() === "agent")
      await logAgentUpdatingPatientMedicalHistory();
    setIsLoading(false);
    if (errorArr.length === 0) navigateIfDone();
  };
  const fetchUserInfo = () => {
    requestPatientInfo("GET")
      .then((res) => {
        setUserInfo(res);
      })
      .catch((err) => console.log(err));
  };

  const openPreviousSymptomsModal = () => {
    setModal(
      <PreviousConditionsModal
        cancelModal={() => setModal(null)}
        saveData={savePreviousConditions}
        items={conditionsState}
        previousConditionsStatus={previousConditionsStatus}
        onPreviousConditionsStatusChanged={(e: boolean) => {
          setNewPreviousConditionsStatus(e);
        }}
      />
    );
  };

  const onSavingMedicationAllergyModal = (e: any) => {
    newMedicationsAllergy
      ? setNewMedicationsAllergy([...newMedicationsAllergy, ...e])
      : setNewMedicationsAllergy([...e]);
  };

  const openMedicationsModal = () => {
    setModal(
      <MedicationsModal
        cancelModal={() => setModal(null)}
        saveData={(ms) => {
          setMedicationsState(ms);
          setModal(null);
        }}
        saveMedications={(e) => {
          onSavingMedicationAllergyModal(e);
        }}
        onMedicationsRemoved={onMedicationsAllergyRemoved}
        items={medicationsState}
        medicationsAllergies={medicationsAllergy}
        medicationAllergy={allergic}
        onChangeMedicationAllergyStatus={(e: boolean) => {
          if (e === false) setModal(null);
          setNewAllergic(e);
        }}
      />
    );
  };

  const renderSaveSettingButton = (style: any) => {
    return (
      <LoadingButton
        isLoading={isLoading}
        color="success"
        style={style}
        onClick={confirmChanges}
      >
        Save Settings
      </LoadingButton>
    );
  };
  const flowNav = (
    <div className="flow-nav-tools">
      <Link to={Routes.homePatient} className="btn btn-lg btn-link control-nav">
        Cancel
      </Link>
      {renderSaveSettingButton({ marginRight: "auto", width: "50%" })}
    </div>
  );
  const renderFemaleOption = () => {
    return (
      <div>
        <FormGroup check inline>
          <div className="custom-checkbox custom-control">
            <input
              onChange={(e) => {
                setMeasurmentsState({
                  ...measurmentsState,
                  pregnant: e.target.checked,
                });
              }}
              ref={pregnantRef}
              id="pregnant"
              type="checkbox"
              defaultChecked={measurmentsState?.pregnant}
              // disabled={!enabled}
              className="custom-control-input"
            />
            <Label className="custom-control-label" for="pregnant">
              Pregnant
            </Label>
          </div>
        </FormGroup>
        <FormGroup check inline>
          <div className="custom-checkbox custom-control">
            <input
              onChange={(e) => {
                setMeasurmentsState({
                  ...measurmentsState,
                  breastfeeding: e.target.checked,
                });
              }}
              id="breastFeeding"
              ref={breastfeedingRef}
              type="checkbox"
              defaultChecked={measurmentsState?.breastfeeding}
              // disabled={!enabled}
              className="custom-control-input"
            />
            <Label className="custom-control-label" for="breastFeeding">
              Breast Feeding
            </Label>
          </div>
        </FormGroup>
      </div>
    );
  };

  const onMedicationsAllergyRemoved = (e: any) => {
    setNewMedicationsAllergy(
      newMedicationsAllergy?.filter((item) => {
        return item.Name !== e.Name;
      })
    );
    setMedicationsAllergy(
      medicationsAllergy.filter((item: any) => {
        return item.id !== e.id;
      })
    );
    deletedMedicationsAllergy
      ? setDeletedMedicationsAllergy([...deletedMedicationsAllergy, e])
      : setDeletedMedicationsAllergy([e]);
  };
  const onMedicationsAllergyEdited = (e: any) => {
    if (
      medicationsAllergy &&
      medicationsAllergy.filter((item: any) => {
        return item.id === e.id;
      }).length > 0
    )
      {
        const updatedMedicationsAllergy = medicationsAllergy.map((item:IFullAllergy) => {
          if (item.id === e.id) {
            return { ...e }; // Merge the original item with the edited allergy (e)
          } else {
            return item; // Leave other allergy items unchanged
          }
        });
        setMedicationsAllergy(updatedMedicationsAllergy)
        setEditedMedicationsAllergy(
          editedMedicationsAllergy ? [...editedMedicationsAllergy, e] : [e]
        );
      }
  
    else if (
      newMedicationsAllergy &&
      newMedicationsAllergy?.filter((item) => {
        return item.Code === e.Code;
      }).length > 0
    )
      setNewMedicationsAllergy([
        ...newMedicationsAllergy.filter((item) => {
          return item.Code !== e.Code;
        }),
        e,
      ]);
  };

  const renderMedicationsAllergyList = () => {
    let medications = newMedicationsAllergy
      ? medicationsAllergy.concat(newMedicationsAllergy)
      : medicationsAllergy;
    return (
      <MedicationsAllergiesList
        onRemove={(e: any) => {
          onMedicationsAllergyRemoved(e);
        }}
        maxHeight={150}
        medicationsAllergy={medications}
        onEdit={(e: any) => {
          onMedicationsAllergyEdited(e);
        }}
      />
    );
  };

  const renderSpanNone = () => {
    return <span>None</span>;
  };
  const renderPreviousConditionsUponStatus = () => {
    if (
      previousConditionsStatus === undefined &&
      newPreviousConditionsStatus === undefined
    )
      return renderPreviousConditions();
    else if (
      previousConditionsStatus === true &&
      newPreviousConditionsStatus === undefined
    )
      return renderPreviousConditions();
    else if (
      previousConditionsStatus === false &&
      newPreviousConditionsStatus === undefined
    )
      return renderSpanNone();
    else if (newPreviousConditionsStatus === true)
      return renderPreviousConditions();
    else if (newPreviousConditionsStatus === false) return renderSpanNone();
  };
  const renderPreviousConditions = () => {
    return conditionsState
      .filter((v) => v.checked)
      .map((con, index, arr) => {
        return (
          <span key={index}>
            {con.display}
            {index + 1 < arr.length && <span className="mr-1">, </span>}
          </span>
        );
      });
  };
  const renderMedicationsAllergyUponStatus = () => {
    if (allergic === undefined && newAllergic === undefined)
      return renderMedicationsAllergyList();
    else if (allergic === true && newAllergic === undefined)
      return renderMedicationsAllergyList();
    else if (allergic === false && newAllergic === undefined)
      return renderSpanNone();
    else if (newAllergic === true) return renderMedicationsAllergyList();
    else if (newAllergic === false) return renderSpanNone();
  };

  const renderMeasurements = () => {
    return (
      <>
        <h6 className="mb-2">Measurements </h6>
        <fieldset disabled={isLoading}>
          <Form>
            <div className="row">
              <div className="col-md-4">
                <dl>
                  <dt>
                    Height (Ft): <Asterisk />
                  </dt>{" "}
                  <dd>
                    {
                      <TextField
                        ref={ftRef}
                        type="number"
                        labelText=""
                        value={measurmentsState.ft}
                        style={{ padding: 8, width: "50%", marginTop: 10 }}
                      />
                      // :   measurmentsState.inch ? measurmentsState.ft + " ft" : "Not set "
                    }
                  </dd>
                </dl>
              </div>
              <div className="col-md-4">
                <dl>
                  <dt>
                    Height (In): <Asterisk />
                  </dt>{" "}
                  <dd>
                    <TextField
                      ref={inchRef}
                      type="number"
                      labelText=""
                      value={measurmentsState.inch}
                      style={{ padding: 8, width: "50%", marginTop: 10 }}
                    />
                    {/* // :  measurmentsState.inch ? measurmentsState.inch + " in" : "Not set " */}
                  </dd>
                </dl>
              </div>
              <div className="col-md-4">
                <dl>
                  <dt>
                    Weight (Lbs): <Asterisk />
                  </dt>{" "}
                  <dd>
                    <TextField
                      ref={lbsRef}
                      value={measurmentsState.lbs}
                      type="number"
                      labelText=""
                      style={{ padding: 8, width: "50%", marginTop: 10 }}
                    />
                    {/* : measurmentsState.lbs ? measurmentsState.lbs + " lbs" : "Not set " */}
                  </dd>
                </dl>
              </div>
            </div>
          </Form>
        </fieldset>
        <h6 className="mb-3">
          Gender <Asterisk />
        </h6>{" "}
        <div>
          <fieldset disabled={isLoading}>
            <Form>
              <UncontrolledButtonDropdown>
                <DropdownToggle>
                  {measurmentsState.gender || "Set Gender"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault();
                      let state = { ...measurmentsState };
                      state.gender = "Male";
                      setMeasurmentsState(state);
                      return false;
                    }}
                  >
                    Male
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault();
                      let state = { ...measurmentsState };
                      state.gender = "Female";
                      setMeasurmentsState(state);
                      return false;
                    }}
                  >
                    Female
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>

              {measurmentsState?.gender == "Female"
                ? renderFemaleOption()
                : null}
            </Form>
          </fieldset>
        </div>
      </>
    );
  };

  return (
    <BigCardFrame
      flowNav={flowNav}
      modal={modal}
      navItem={navItem}
      bodyClassName="tc-bg"
    >
      <div className="card-body">
        <div className="d-flex px-2">
          <h5 className="mb-0">Medical Profile</h5>
          <Link
            to={Routes.homePatient}
            className="btn btn-link btn-sm"
            style={{ marginLeft: "auto" }}
          >
            Cancel
          </Link>
          {renderSaveSettingButton({ marginLeft: "auto" })}
        </div>
        <hr className="mt-2 mb-4"></hr>
        <div className="container">
          <h5 className="card-title">
            Please review your medical profile below and edit any
            inconsistencies
          </h5>
          {renderMeasurements()}
          <hr></hr>
          <div className="mt-3">
            <h6 className="mb-3">
              Previous Conditions{" "}
              <Button
                disabled={isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  openPreviousSymptomsModal();
                  return false;
                }}
                data-toggle="modal"
                data-target="#updateMedHistoryModal"
                data-med-history-type="Symptoms"
                className="btn btn-sm btn-link"
                color="link"
              >
                Add
              </Button>
            </h6>
            <div>{renderPreviousConditionsUponStatus()}</div>
            <hr></hr>
          </div>
          <h6 className="mb-3">
            Medication Allergies{" "}
            <Button
              disabled={isLoading}
              onClick={openMedicationsModal}
              color="link"
              size="sm"
              data-toggle="modal"
              data-target="#medsAllergicModal"
              data-med-history-type="Medication Allergies"
            >
              Add
            </Button>
          </h6>
          <div className="w-75">
            <ul className="list-group list-group-flush">
              {renderMedicationsAllergyUponStatus()}
            </ul>
          </div>
          <hr></hr>
          <SelfReportedMedications
            isLoading={isLoading}
            setSelfReportedMedicationsActions={
              setSelfReportedMedicationsActions
            }
          />
          <hr></hr>
          <MembersInHousehold
            isLoading={isLoading}
            info={userInfo}
            fetchData={fetchUserInfo}
          />
        </div>
      </div>
    </BigCardFrame>
  );
};