import React, { useEffect, useRef, useState } from 'react';
import { IProps } from '../../auth/PatientSignUp';
import TCNavbar from '../../../components/layout/TCNavBar';
import { navItem } from '../../../App';
import { Col, Alert, Container, Row, Card, CardBody, CardTitle, Table, Button, Badge, Input } from 'reactstrap';
import { getApointemetsForBiller, getBillerStatus, appointmentBillingUpdate } from '../../../core/service/services';
import Footer from '../../../components/layout/Footer';
import { UserConfig, IUserInfo } from '../../../config/userConfig';
import _ from 'lodash';
import { CustomedPagination } from '../admin/Pagination';
import { PatientModal } from './PatientModal';
import moment from 'moment';
import { InsuranceInfoModal } from './InsuranceInfoModal';
import { ManageInsuranceModal, IAppointementBilling } from './ManageInsuranceModal';
import { BillerSearchBar } from './BillerSearchBar';
import { BillingLogsModal } from './BillingLogsModal';
import { IBillingAddress } from '../../../core/service/services';

export enum AgentBillerStatus {
  Active = "Active",
  Disabled = "Disabled",
  New = "New"
}

export interface IBillerSearch {
  appointmentNumber?: string;
  payerCode?: string;
  appointmentDateFrom?: string;
  appointmentDateTo?: string
  billingStatus?: BillingStatus;
  patientName?: string;
  patientPhone?: string
  patientEmail?: string
  patientID?: number;
  insuranceListed?: boolean
  payerName?: string
}

export enum BillingStatus {
  Pending = "Pending",
  Failed = "Failed",
  Completed = "Completed"
}

export interface IAppointmentBiller {
   appointmentDate: Date;
   appointmentNumber: string;
   patient: IPatientInfoBiller;
   billingStatus: BillingStatus;
   insurance?: IInsuranceInfoBiller;
   insuranceAttachments?: string[];
   copayment?: ICopaymentBiller;
   primarySubscriber?: IPrimarySubscriberInfo;
   appointmentBilling?: IAppointementBilling;
   payment: {
      amount: string;
      discount: string;
      promoCode: string;
   };
}
interface ICopaymentBiller {
  id: string,
  amount: number
}

export interface IInitialFees {

  appointmentPaymentAmount: number,
  appointmentTransactionFee: number

}
export interface IPrimarySubscriberInfo {

  id: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  relation: string,
}


export interface IPatientInfoBiller {
  name: string;
  dateOfBirth: Date;
  address: string;
  phoneNumber: string;
  email: string;
  gender: "f" | "m";
  id: string;
  fullAddress: IBillingAddress;
}
export interface IInsuranceInfoBiller {
  providerName: string,
  dateOfBirth: Date,
  cptCoded: string,
  diagnosis: string,
  payerName: string,
  payerCode: string,
  insuranceId: string,
  memberId: string,
  secondDiagnosis?: string
}

export interface IBillingStatusToUpdate {
  appointmentNumber: string;
  billingStatus: BillingStatus;
}

interface IHomeBillerProps extends IProps {

}

const PAGE_SIZE = 20

export const HomeBiller: React.FunctionComponent<IHomeBillerProps> = (props: IHomeBillerProps) => {
  const [billerSatus, setBillerStatus] = useState<AgentBillerStatus>();
  const [userInfo, setUserInfo] = useState<IUserInfo | null>();
  const [appointmentsToShow, setAppointmentsToShow] = useState<IAppointmentBiller[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [searchOptions, setSearchOptions] = useState<any>()
  const [initialFees, setInitialFees] = useState<IInitialFees | undefined>(undefined);
  const controllerRef = useRef<AbortController | null>();
  const [showPatientModal, setShowPatientModal] = useState(false);
  const toggleShowPatientModal = () => setShowPatientModal(!showPatientModal);
  const [currentAppointementSelected, setCurrentAppointementSelected] = useState<IAppointmentBiller | undefined>(undefined)
  const [showInsruanceInfoModal, setShowInsruanceInfoModal] = useState(false);
  const toggleShowInsuranceInfoModal = () => setShowInsruanceInfoModal(!showInsruanceInfoModal);
  const [showManageInsuranceModal, setShowManageInsuranceModal] = useState(false);
  const toggleShowManageInsuranceModal = () => setShowManageInsuranceModal(!showManageInsuranceModal);
  const [showBillingLogsModal, setShowBillingLogsModal] = useState(false);
  const toggleShowBillingLogsModal = () => setShowBillingLogsModal(!showBillingLogsModal);

  useEffect(() => {
    let user = UserConfig.getUserInfo();
    setUserInfo(user);
    getStatus();
    fetchAllApointements(pageIndex, PAGE_SIZE, undefined)
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0)
  })


  const fetchAllApointements = (pageIndex: number, pageSize: number, searchOptions: any) => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    const obj = _.valuesIn(searchOptions).toString().replace(",", "") != "" ? searchOptions : undefined
    getApointemetsForBiller(pageSize, pageIndex, obj, controllerRef.current?.signal).then(res => {
      res.pageSize != 0 && setPageCount(Math.ceil(res.count / res.pageSize))
      controllerRef.current = null;
      if (pageIndex && pageIndex !== 0 && pageIndex !== res.pageIndex && res.pageIndex !== 0) {
        setPageIndex(res.pageIndex)
      }
      res.pageSize != 0 && setPageCount(Math.ceil(res.count / res.pageSize))
      if (res.appointments && res.appointments.length !== 0) {
        setAppointmentsToShow(res.appointments);
      } else {
        setAppointmentsToShow([]);
      }
      setInitialFees(res.initialFees)
    }).catch(err => console.log(err))
  }

  const getStatus = () => {
    getBillerStatus()
      .then((result: { status: AgentBillerStatus }) => {
        setBillerStatus(result.status);
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const changeProcessedValue = (newValue: boolean, appointmentId: string) => {
    appointmentBillingUpdate({ processed: newValue }, appointmentId)
      .then((updatedAppointment: IAppointmentBiller | any) => {
        const newList = appointmentsToShow.map((item) => {
          if (item.appointmentBilling) {
            if (item.appointmentNumber === updatedAppointment.appointmentNumber) {
              item.appointmentBilling.processed = updatedAppointment.appointmentBilling!.processed;
            }
          }
          return item;
        });
        setAppointmentsToShow(newList);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const updateBillingStatus = () => {
    const newList = appointmentsToShow.map((item) => {
      if (item.appointmentNumber === currentAppointementSelected?.appointmentNumber) {
        let updatedItem: IAppointmentBiller = {
          ...item,
          billingStatus: BillingStatus.Completed
        };
        return updatedItem;
      }
      return item;
    });
    setAppointmentsToShow(newList);
  }

  const applySearch = (searchOpts: any) => {
    setSearchOptions(searchOpts)
    fetchAllApointements(0, PAGE_SIZE, searchOpts)
    setPageIndex(0)
  }

  const renderAppointementTableBody = () => {
    if (appointmentsToShow && appointmentsToShow.length > 0) {
      return (
        <>
          {appointmentsToShow.map(appointment => (
            <tr key={appointment.appointmentNumber} >
              <td>{appointment.appointmentNumber}</td>
              <td>{renderBillingStatus(appointment)}</td>
              <td>{renderPatient(appointment)}</td>
              <td>{renderInsurance(appointment)}</td>
              <td>{renderDiagnosis(appointment)} </td>
              <td>{appointment?.insurance?.cptCoded} </td>
              <td>{appointment.copayment?.amount}</td>
              <td>{appointment.patient.fullAddress.zipCode}</td>
              <td>{moment(appointment.appointmentDate).format("MM/DD/YYYY ")}</td>
              <td>{renderActions(appointment)}</td>
              <td>{renderProcessedCheckbox(appointment)}</td>
            </tr>
          ))}
        </>
      )
    }
  }

  const renderDiagnosis = (appointment: IAppointmentBiller) => {
    if (appointment.insurance?.secondDiagnosis) {
      return (
        <>
          {appointment?.insurance?.diagnosis}
          <br />
          {appointment?.insurance?.secondDiagnosis}
        </>
      )
    } else {
      return (
        <>
          {appointment?.insurance?.diagnosis}
        </>
      )
    }
  }

  const renderAppointements = () => {
    if (appointmentsToShow && appointmentsToShow.length > 0) {
      return (
        <>
          <Table responsive bordered size="sm" hover variant="dark"  >
            <thead>
              <th>Appointment Number</th>
              <th>Billing Status</th>
              <th>Patient </th>
              <th>Insurance</th>
              <th>Diagnosis</th>
              <th>CPT </th>
              <th>Co-Payment</th>
              <th>ZIP Code</th>
              <th>Appointment Date</th>
              <th>Actions</th>
              <th>Processed</th>
            </thead>
            {renderAppointementTableBody()}
          </Table>
        </>
      )
    }

  }

  const renderInsurance = (appointment: IAppointmentBiller) => {
    return (
      <>
        <Button color="link" size="sm" id="patient"
          onClick={() => {
            setCurrentAppointementSelected(appointment)
            toggleShowInsuranceInfoModal()
          }}
        >
          {appointment.insuranceAttachments ? "Not Listed" : appointment?.insurance?.payerName}
        </Button>
      </>
    )
  }

  const renderBillingStatus = (appointment: IAppointmentBiller) => {
    switch (appointment.billingStatus) {
      case BillingStatus.Completed:
        return (
          <Badge color={"success"} className="p-1 mr-1 ">{BillingStatus.Completed}</Badge>
        )
      case BillingStatus.Pending:
        return (
          <Badge color={"warning"} className="p-1 mr-1 ">{BillingStatus.Pending}</Badge>
        )
    }
  }

  const renderPatient = (appointment: IAppointmentBiller) => {
    return (
      <>
        <Button color="link" size="sm" id="patient"
          onClick={() => {
            setCurrentAppointementSelected(appointment)
            toggleShowPatientModal()
          }}
        >
          {appointment.patient.name}
        </Button>
      </>
    )
  }

  const renderActions = (appointment: IAppointmentBiller) => {
    return (
      <div style={{ margin: "6%" }}>
        <Row>
          <Button color="link" size="sm" id="phtoggler"
            onClick={() => {
              setCurrentAppointementSelected(appointment)
              toggleShowManageInsuranceModal()
            }}
          >
            Manage
          </Button>
          <Button color="link" size="sm" id="phtoggler"
            onClick={() => {
              setCurrentAppointementSelected(appointment)
              toggleShowBillingLogsModal()
            }}
          >
            Billing Logs
          </Button>
        </Row>
      </div>
    )
  }

  const renderProcessedCheckbox = (appointment: IAppointmentBiller) => {
    let billing = appointment.appointmentBilling;
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <Input
          id="processed"
          type="checkbox"
          defaultChecked={billing ? billing.processed === 1 : false}
          disableUnderline={true}
          style={{ position: "static", marginLeft: "0" }} //overriding default Input styles
          onChange={(e) => {
            changeProcessedValue(e.target.checked, appointment.appointmentNumber);
          }}
        />
      </div>
    )
  }

  const renderAppointmentsTable = () => (
    <Row>
      <Col lg="12" xl="12">
        <h5 className="text-white mx-3">
          <i className="fa fa-calendar"></i> Appointments
        </h5>
        <Card className="card-telecare mt-3 mb-5">
          <br />
          <BillerSearchBar searchFilters={applySearch}
          ></BillerSearchBar>
          {appointmentsToShow.length === 0 &&
            <>
              <div className='centeHeader'>
                <h3>No Appointements Available </h3>


              </div>

            </>
          }
          <CardBody>
            {renderAppointements()}
            {appointmentsToShow.length > 0 && pageCount &&
              <CustomedPagination
                pageCount={pageCount}
                pageNum={pageIndex}
                onPaginate={(idx: any) => { setPageIndex(idx); fetchAllApointements(idx, PAGE_SIZE, searchOptions) }}
              />}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )

  return (
    <>
      <TCNavbar bodyClassName="tc-bg">{navItem}</TCNavbar>
      <PatientModal appointment={currentAppointementSelected} isOpen={showPatientModal} toggleModal={toggleShowPatientModal}></PatientModal>
      <InsuranceInfoModal appointement={currentAppointementSelected} isOpen={showInsruanceInfoModal} toggleModal={toggleShowInsuranceInfoModal}></InsuranceInfoModal>
      <BillingLogsModal appointement={currentAppointementSelected} isOpen={showBillingLogsModal} toggleModal={toggleShowBillingLogsModal}></BillingLogsModal>
      <ManageInsuranceModal
        updateBillingStatus={updateBillingStatus}
        initialFees={initialFees}
        appointement={currentAppointementSelected}
        isOpen={showManageInsuranceModal}
        toggleModal={toggleShowManageInsuranceModal}
      />
      <Container >
        <Row>
          {(billerSatus === AgentBillerStatus.Active) &&

            <Col lg="12" xl="12">
              <Card className="card-telecare my-5">
                <CardBody>
                  <CardTitle className="mb-0">
                    {"Welcome "}
                    <span className="currentUserName">{userInfo?.firstname || " "}</span>
                  </CardTitle>
                </CardBody>
              </Card>
              {renderAppointmentsTable()}
            </Col>
          }

          <Col lg={billerSatus == AgentBillerStatus.Disabled || billerSatus == AgentBillerStatus.New ? "12" : "4"} xl={billerSatus === AgentBillerStatus.Disabled || billerSatus === AgentBillerStatus.New ? "12" : "4"}>
            {billerSatus === AgentBillerStatus.New && <Alert className="mt-4" color="danger">Pending system administrator approval!</Alert>}
            {billerSatus === AgentBillerStatus.Disabled && <Alert className="mt-4" color="danger">Agent account is disabled.</Alert>}
          </Col>
        </Row>

      </Container >

      <Footer />
    </>
  )
}